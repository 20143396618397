<template>
  <section>
    <div
      v-if="mode == 'editor'"
      class="editor-toolbar"
      :class="isEditing ? 'editing' : ''"
    >
      <slot name="toolbar"></slot>
    </div>
    <EquipmentTablePanel
      v-bind:equipmentList="entryList"
      v-bind:equipmentCustomFields="equipmentCustomFields"
      v-bind:showName="true"
      v-bind:showLocation="true"
      v-bind:showAlarmStatus="true"
      v-bind:showConnectionStatus="true"
      v-bind:showSerialNumber="true"
      v-bind:showProcessArea="true"
      v-bind:showPagination="false"
      v-bind:ids="(equipment && [equipment.id]) || []"
      v-on:select="onSelect"
    >
    </EquipmentTablePanel>
    <div class="empty" v-if="!deviceList || !deviceList.length">
      {{ $t("no_equipment_was_found") }}
    </div>
  </section>
</template>

<script>
import EquipmentTablePanel from "@/components/equipment-table-panel.vue";

export default {
  name: "EquipmentTableStationPanel",
  components: {
    EquipmentTablePanel
  },
  props: {
    equipment: {
      type: Object,
      default: null,
      required: false
    },
    display: {
      type: Object,
      required: true
    },
    panel: {
      type: Object,
      required: true
    },
    mode: {
      type: String,
      default: "viewer",
      required: false
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data() {
    return {
      source: "device",
      fixedEquipmentProperties: [
        "nome",
        "numero_serie",
        "localizacao",
        "has_active_alarms",
        "is_connected",
        "process_area"
      ]
    };
  },
  computed: {
    connectorId() {
      return (this?.equipment?.id && parseInt(this.equipment.id)) || "";
    },
    deviceList() {
      return this?.connectorId
        ? (this.$store.getters["dashboard/deviceList"] || []).filter(
            ({connector}) => parseInt(connector.id) == this.connectorId
          )
        : this.$store.getters["dashboard/deviceList"];
    },
    entryList() {
      // device on connector, instead connector in device (connectors are repeated here)
      return this.deviceList.map((device) => {
        let entry = {
          ...device.connector,
          device: {...device}
        };
        delete entry.connector;
        return entry;
      });
    },
    allEquipmentFields() {
      return (
        (this.$root.config.equipment_selection &&
          this.$root.config.equipment_selection.cols) ||
        []
      );
    },
    equipmentCustomFields() {
      return this.allEquipmentFields.filter(({name}) => {
        return this.fixedEquipmentProperties.indexOf(name) == -1;
      });
    }
  },
  // watch: {
  //   connectorId: {
  //     handler(n, o) {
  //       if (!o && n) {
  //         this.$store.dispatch("dashboard/fetchDevicesState", {
  //           connectorIdList: [n]
  //         });
  //       }
  //     },
  //     immediate: true,
  //     deep: true
  //   }
  // },
  methods: {
    onSelect(query) {
      this.$store.commit(
        "SET_DEVICE_ID",
        query && query.device_id ? query.device_id : ""
      );
    }
  }
};
</script>

<style scoped>
.editor-toolbar {
  position: absolute;
  right: 0px;
  width: 100%;
  border-top: 3px solid #3c8dbc;
}

.editing {
  border-top-color: #f39c12;
}

.editor-toolbar > div {
  background-color: white;
}
div.empty {
  text-align: center;
  background-color: whitesmoke;
  margin-top: -20px;
  padding: 10px;
  border-radius: 0 0 5px 5px;
  border: 1px solid #ddd;
}
</style>
